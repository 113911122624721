export const ImagePlaceholder = () => {
  return (
    <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.8627 7.42745C30.8627 6.82125 30.6219 6.23987 30.1933 5.81121C29.7646 5.38256 29.1832 5.14174 28.577 5.14174H24.0056L20.577 0.570312H11.4342L8.00558 5.14174H3.43415C2.82794 5.14174 2.24656 5.38256 1.81791 5.81121C1.38925 6.23987 1.14844 6.82125 1.14844 7.42745V21.1417C1.14844 21.7479 1.38925 22.3293 1.81791 22.758C2.24656 23.1866 2.82794 23.4275 3.43415 23.4275H28.577C29.1832 23.4275 29.7646 23.1866 30.1933 22.758C30.6219 22.3293 30.8627 21.7479 30.8627 21.1417V7.42745Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.01 18.2857C18.8504 18.2857 21.1529 15.9832 21.1529 13.1429C21.1529 10.3025 18.8504 8 16.01 8C13.1697 8 10.8672 10.3025 10.8672 13.1429C10.8672 15.9832 13.1697 18.2857 16.01 18.2857Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
