import { FC } from 'react'

export const CheckSuccessSVG: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33672 15.9193L5.41772 12.0003C5.2302 11.8128 4.97589 11.7075 4.71072 11.7075C4.44556 11.7075 4.19125 11.8128 4.00372 12.0003C3.81625 12.1878 3.71094 12.4421 3.71094 12.7073C3.71094 12.9725 3.81625 13.2268 4.00372 13.4143L7.92272 17.3333C8.10845 17.5191 8.32897 17.6665 8.57167 17.767C8.81437 17.8676 9.07451 17.9194 9.33722 17.9194C9.59994 17.9194 9.86007 17.8676 10.1028 17.767C10.3455 17.6665 10.566 17.5191 10.7517 17.3333L20.0037 8.0813C20.1912 7.89377 20.2965 7.63946 20.2965 7.3743C20.2965 7.10913 20.1912 6.85482 20.0037 6.6673C19.8162 6.47983 19.5619 6.37451 19.2967 6.37451C19.0316 6.37451 18.7773 6.47983 18.5897 6.6673L9.33672 15.9193Z"
        fill="#008001"
      />
    </svg>
  )
}
