import { FC } from 'react'

export const DeleteSVG: FC = () => {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.710938 6.00028H21.2824M6.71094 6.00028V5.14314C6.71094 4.00649 7.16247 2.91641 7.96619 2.11268C8.76992 1.30895 9.86001 0.857422 10.9967 0.857422C12.1333 0.857422 13.2234 1.30895 14.0271 2.11268C14.8308 2.91641 15.2824 4.00649 15.2824 5.14314V6.00028M8.42522 9.42885V18.8574M13.5681 9.42885V18.8574M3.28237 6.00028H18.7109V21.4289C18.7109 21.8835 18.5303 22.3195 18.2088 22.641C17.8873 22.9625 17.4513 23.1431 16.9967 23.1431H4.99665C4.54199 23.1431 4.10596 22.9625 3.78447 22.641C3.46298 22.3195 3.28237 21.8835 3.28237 21.4289V6.00028Z"
        stroke="#E64646"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
