export const QuestionSVG = ({ ...props }) => {
  return (
    <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3125_68941)">
        <path
          d="M12.0022 23.6431C18.1563 23.6431 23.1451 18.6543 23.1451 12.5003C23.1451 6.34625 18.1563 1.35742 12.0022 1.35742C5.8482 1.35742 0.859375 6.34625 0.859375 12.5003C0.859375 18.6543 5.8482 23.6431 12.0022 23.6431Z"
          stroke="#808080"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.42969 9.92885C9.42969 9.42027 9.5805 8.92311 9.86305 8.50024C10.1456 8.07737 10.5472 7.74779 11.0171 7.55316C11.4869 7.35854 12.004 7.30761 12.5028 7.40683C13.0016 7.50605 13.4598 7.75096 13.8194 8.11058C14.179 8.4702 14.4239 8.92838 14.5231 9.42719C14.6224 9.926 14.5714 10.443 14.3768 10.9129C14.1822 11.3828 13.8526 11.7844 13.4297 12.0669C13.0069 12.3495 12.5097 12.5003 12.0011 12.5003V14.2146"
          stroke="#808080"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0045 16.7852C11.7502 16.7852 11.5016 16.8606 11.2902 17.0018C11.0787 17.1431 10.9139 17.3439 10.8166 17.5788C10.7193 17.8138 10.6938 18.0723 10.7435 18.3217C10.7931 18.5711 10.9155 18.8002 11.0953 18.98C11.2751 19.1598 11.5042 19.2823 11.7536 19.3319C12.003 19.3815 12.2616 19.356 12.4965 19.2587C12.7314 19.1614 12.9322 18.9966 13.0735 18.7852C13.2148 18.5737 13.2902 18.3252 13.2902 18.0709C13.2857 17.7313 13.1489 17.4068 12.9087 17.1666C12.6685 16.9265 12.3441 16.7896 12.0045 16.7852Z"
          fill="#808080"
        />
      </g>
      <defs>
        <clipPath id="clip0_3125_68941">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
