import { FC } from 'react'

export const EditSVG: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.859375 23.1433H19.7165M11.1451 17.1433L6.00223 18.069L6.85938 12.8576L18.3965 1.35474C18.5559 1.19406 18.7455 1.06653 18.9544 0.979497C19.1633 0.892465 19.3874 0.847656 19.6137 0.847656C19.84 0.847656 20.064 0.892465 20.2729 0.979497C20.4818 1.06653 20.6714 1.19406 20.8308 1.35474L22.6479 3.17188C22.8086 3.33125 22.9362 3.52085 23.0232 3.72975C23.1102 3.93865 23.155 4.16272 23.155 4.38902C23.155 4.61533 23.1102 4.8394 23.0232 5.0483C22.9362 5.2572 22.8086 5.4468 22.6479 5.60617L11.1451 17.1433Z"
        stroke="#808080"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
